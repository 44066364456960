
@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import url("css/fontawesome-all.min.css");
@import url("css/font-source-sans-pro.css");

/*
    Arcana by HTML5 UP
    html5up.net | @ajlkn
    Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/


// Breakpoints.

    @include breakpoints((
        wide:      ( 1281px,  1680px ),
        normal:    ( 981px,   1280px ),
        narrow:    ( 841px,   980px  ),
        narrower:  ( 737px,   840px  ),
        mobile:    ( 481px,   736px  ),
        mobilep:   ( null,    480px  )
    ));

// Reset.
// Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain)

    html, body, div, span, applet, object,
    iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
    pre, a, abbr, acronym, address, big, cite,
    code, del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var, b,
    u, i, center, dl, dt, dd, ol, ul, li, fieldset,
    form, label, legend, table, caption, tbody,
    tfoot, thead, tr, th, td, article, aside,
    canvas, details, embed, figure, figcaption,
    footer, header, hgroup, menu, nav, output, ruby,
    section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
    }

    ol, ul {
        list-style:none;
    }

    blockquote, q {
        quotes: none;

        &:before,
        &:after {
            content: '';
            content: none;
        }
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    body {
        -webkit-text-size-adjust: none;
    }

    mark {
        background-color: transparent;
        color: inherit;
    }

    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    input, select, textarea {
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        appearance: none;
    }

/* Basic */

    // Set box model to border-box.
    // Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
        html {
            box-sizing: border-box;
        }

        *, *:before, *:after {
            box-sizing: inherit;
        }

    body {
        background: _palette(bg) url('images/xv.png');

        // Stops initial animations until page loads.
            &.is-preload {
                *, *:before, *:after {
                    @include vendor('animation', 'none !important');
                    @include vendor('transition', 'none !important');
                }
            }

    }
    
    body.splash {
        background: url('images/Welcome_image_shadow.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        background-size: cover;
    }
    

    
/*    @media (max-width: 768px) {
        body.splash {
            background: url('images/pexels-evie-shaffer-2748757_narrow.jpg');
        }
    }*/

    body, body.splash input, select, textarea {
        color: _palette(fg);
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16pt;
        font-weight: 300;
        line-height: 1.65em;
    }

    a {
        @include vendor('transition', 'color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out');
        color: _palette(accent, alt);
        text-decoration: none;
        border-bottom: dotted 1px;

        &:hover {
            color: _palette(accent, bg);
            border-bottom-color: transparent;
        }
    }

    strong, b {
        font-weight: 600;
    }

    em, i {
        font-style: italic;
    }

    p, ul, ol, dl, table, blockquote {
        margin: 0 0 2em 0;
    }

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
        font-weight: 600;
        line-height: 1.75em;
        margin-bottom: 1em;

        a {
            color: inherit;
            text-decoration: none;
        }

        em {
            font-style: normal;
            font-weight: 300;
        }
    }

    h1 {
        font-size: 2.00em;
        letter-spacing: -0.025em;
    }

    h2 {
        font-size: 1.75em;
        letter-spacing: -0.025em;
    }

    h3 {
        font-size: 1.2em;
        letter-spacing: -0.025em;
    }

    sub {
        font-size: 0.8em;
        position: relative;
        top: 0.5em;
    }

    sup {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }

    hr {
        border-top: solid 1px _palette(border);
        margin-bottom: 1.5em;
    }

    blockquote {
        border-left: solid 0.5em _palette(border);
        font-style: italic;
        padding: 1em 0 1em 2em;
    }

    time {
        font-size: 1rem;
    }

/* Container */

    .container {
        margin: 0 auto;
        max-width: 100%;
        width: 1400px;

        @include breakpoint('<=wide') {
            width: 1200px;
        }

        @include breakpoint('<=normal') {
            width: 960px;
        }

        @include breakpoint('<=narrow') {
            width: 95%;
        }

        @include breakpoint('<=narrower') {
            width: 95%;
        }

        @include breakpoint('<=mobile') {
            width: 90%;
        }

        @include breakpoint('<=mobilep') {
            width: 100%;
        }
    }

/* Row */

    .row {
        @include html-grid((50px, 50px));

        @include breakpoint('<=wide') {
            @include html-grid((40px, 40px), 'wide');
        }

        @include breakpoint('<=normal') {
            @include html-grid((30px, 30px), 'normal');
        }

        @include breakpoint('<=narrow') {
            @include html-grid((30px, 30px), 'narrow');
        }

        @include breakpoint('<=narrower') {
            @include html-grid((30px, 30px), 'narrower');
        }

        @include breakpoint('<=mobile') {
            @include html-grid((20px, 20px), 'mobile');
        }

        @include breakpoint('<=mobilep') {
            @include html-grid((20px, 20px), 'mobilep');
        }
    }

/* Section/Article */

    section, article {
        &.special {
            text-align: center;
        }
    }

    header {
        p {
            color: _palette(fg-light);
            font-size: 1.25em;
            position: relative;
            margin-top: -1.25em;
            margin-bottom: 0.25em;
        }

        &.major {
            text-align: center;
            margin: 0 0 2em 0;

            h2 {
                font-size: 2.25em;
            }

            p {
                position: relative;
                border-top: solid 1px _palette(border);
                padding: 1em 0 0 0;
                margin: 0;
                top: -1em;
                font-size: 1.5em;
                letter-spacing: -0.025em;
            }
        }
    }

    footer {
        margin: 0 0 3em 0;

        > :last-child {
            margin-bottom: 0;
        }

        &.major {
            padding-top: 3em;
        }
    }

/* Form */

    input[type="text"],
    input[type="password"],
    input[type="email"],
    textarea {
        @include vendor('appearance', 'none');
        @include vendor('transition', 'border-color 0.2s ease-in-out');
        background: #fff;
        border: solid 1px _palette(border);
        border-radius: _size(radius);
        color: inherit;
        display: block;
        outline: 0;
        padding: 0.75em;
        text-decoration: none;
        width: 100%;

        &:focus {
            border-color: _palette(accent, bg);
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="email"] {
        line-height: 1em;
    }

    label {
        display: block;
        color: inherit;
        font-weight: 600;
        line-height: 1.75em;
        margin-bottom: 0.5em;
    }

    ::-webkit-input-placeholder {
        color: _palette(fg-light);
        position: relative;
        top: 3px;
    }

    :-moz-placeholder {
        color: _palette(fg-light);
    }

    ::-moz-placeholder {
        color: _palette(fg-light);
    }

    :-ms-input-placeholder {
        color: _palette(fg-light);
    }

/* Image */

    .image {
        border: 0;
        display: inline-block;
        position: relative;
        border-radius: _size(radius);

        img {
            display: block;
            border-radius: _size(radius);
        }

        &.left {
            display: block;
            float: left;
            margin: 0 2em 2em 0;
            position: relative;
            top: 0.25em;

            img {
                display: block;
                max-width: 100%;
            }
        }

        &.fit {
            display: block;

            img {
                display: block;
                width: 100%;
            }
        }

        &.featured {
            display: block;
            margin: 0 0 2em 0;

            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

/* Icon */

    .icon {
        @include icon;
        position: relative;
        text-decoration: none;

        > .label {
            display: none;
        }

        &:before {
            line-height: inherit;
        }

        &.solid {
            &:before {
                font-weight: 900;
            }
        }

        &.brands {
            &:before {
                font-family: 'Font Awesome 5 Brands';
            }
        }

        &.major {
            text-align: center;
            cursor: default;
            background-color: _palette(accent, bg);
/*            @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.15))', 'url("images/bg01.png")'));*/
            color: _palette(accent, fg);
            border-radius: 100%;
            display: inline-block;
            width: 5em;
            height: 5em;
            line-height: 5em;
/*            box-shadow: 0 0 0 7px white, 0 0 0 8px _palette(border);*/
            margin: 0 0 2em 0;

            &:before {
                font-size: 36px;
            }
        }
    }

/* Lists */

    ol {
        list-style: decimal;
        padding-left: 1.25em;

        li {
            padding-left: 0.25em;
        }
    }

    ul {
        list-style: disc;
        padding-left: 1em;

        li {
            padding-left: 0.5em;
        }
    }

    ul.tags {
        list-style: none;
        font-size: 1rem;
        display: flex;
        padding-left: 0;
        margin-left: -8px;
    }

/* Links */

    ul.links {
        list-style: none;
        padding-left: 0;

        li {
            line-height: 2.5em;
            padding-left: 0;
        }
    }

/* Icons */

    ul.icons {
        cursor: default;
        list-style: none;
        padding-left: 0;

        li {
            display: inline-block;
            line-height: 1em;
            padding-left: 1.5em;

            &:first-child {
                padding-left: 0;
            }

            a, span {
                font-size: 2em;
                border: 0;
            }
        }
    }

/* Menu */

    ul.menu {
        list-style: none;
        padding-left: 0;

        li {
            border-left: solid 1px _palette(border);
            display: inline-block;
            padding: 0 0 0 1em;
            margin: 0 0 0 1em;

            &:first-child
            {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
            }
        }
    }

/* Actions */

    ul.actions {
        @include vendor('display', 'flex');
        cursor: default;
        list-style: none;
        margin-left: -1em;
        padding-left: 0;

        li {
            padding: 0 0 0 1em;
            vertical-align: middle;
        }

        &.special {
            @include vendor('justify-content', 'center');
            width: 100%;
            margin-left: 0;

            li {
                &:first-child {
                    padding-left: 0;
                }
            }
        }

        &.stacked {
            @include vendor('flex-direction', 'column');
            margin-left: 0;

            li {
                padding: 1.25em 0 0 0;

                &:first-child {
                    padding-top: 0;
                }
            }
        }

        &.fit {
            width: calc(100% + 1em);

            li {
                @include vendor('flex-grow', '1');
                @include vendor('flex-shrink', '1');
                width: 100%;

                > * {
                    width: 100%;
                }
            }

            &.stacked {
                width: 100%;
            }
        }

        @include breakpoint('<=mobile') {
            &:not(.fixed) {
                @include vendor('flex-direction', 'column');
                margin-left: 0;
                width: 100% !important;

                li {
                    @include vendor('flex-grow', '1');
                    @include vendor('flex-shrink', '1');
                    padding: 1em 0 0 0;
                    text-align: center;
                    width: 100%;

                    > * {
                        width: 100%;
                    }

                    &:first-child {
                        padding-top: 0;
                    }

                    input[type="submit"],
                    input[type="reset"],
                    input[type="button"],
                    button,
                    .button {
                        width: 100%;

                        &.icon {
                            &:before {
                                margin-left: -0.5em;
                            }
                        }
                    }
                }
            }
        }
    }

/* Tables */

    table {
        width: 100%;

        &.default {
            width: 100%;

            tbody {
                tr {
                    border-bottom: solid 1px _palette(border);
                }
            }

            td {
                padding: 0.5em 1em 0.5em 1em;
            }

            th {
                font-weight: 600;
                padding: 0.5em 1em 0.5em 1em;
                text-align: left;
            }

            thead {
                background-color: #555555;
                @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.15))', 'url("images/xv.png")'));
                color: #fff;
            }

            tfoot {
            }

            tbody {
            }
        }
    }

/* Button */

    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        @include vendor('appearance', 'none');
        @include vendor('transition', 'background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out');
/*        @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.15))', 'url("images/bg01.png")')); */
        background-color: _palette(accent, bg);
        border-radius: _size(radius);
        border: 0;
        color: _palette(accent, fg);
        cursor: pointer;
        display: inline-block;
        padding: 0 1.5em;
        line-height: 2.75em;
        min-width: 9em;
        text-align: center;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: -0.025em;

        &:hover {
            background-color: lighten(_palette(accent, bg), 5);
            color: _palette(accent, fg) !important;
        }

        &:active {
            background-color: darken(_palette(accent, bg), 5);
            color: _palette(accent, fg);
        }

        &.alt {
            background-color: #555555;
            color: #fff;

            &:hover {
                background-color: lighten(#555555, 5);
            }

            &:active {
                background-color: darken(#555555, 5);
            }
        }

        &.icon {
            &:before {
                margin-right: 0.5em;
            }
        }

        &.fit {
            width: 100%;
        }

        &.small {
            font-size: 0.8em;
        }
    }

/* Box */

    .box {
        &.highlight {
            text-align: center;
        }

        &.post {
            position: relative;
            margin: 0 0 2em 0;

            &:after {
                content: '';
                display: block;
                clear: both;
            }

            .inner {
                margin-left: calc(30% + 2em);

                > :last-child {
                    margin-bottom: 0;
                }
            }

            .image {
                width: 30%;
                margin: 0;
            }
        }
    }

/* Header */

    #header {
        text-align: center;
        padding: 0 0 0 0;
        background-color: #fff;
        background-image:           url('images/bg02.png'),     url('images/bg02.png'),     url('images/bg01.png');
        background-position:        top left,                   top left,                   top left;
        background-size:            100% 6em,                   100% 6em,                   auto;
        background-repeat:          no-repeat,                  no-repeat,                  repeat;

        h1 {
            padding: 0 0 0 0;
            margin: 0;

            a {
                font-size: 1.5em;
                letter-spacing: -0.025em;
                border: 0;
            }
        }
    }

    #nav {
        cursor: default;
        background-color: #427069;
        @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.3))', 'url("images/bg01.png")'));
        padding: 0;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 0.75em;
            background-color: _palette(accent, bg);
/*            background-image: url("images/bg01.png");*/
        }

        > ul {
            margin: 0;

            > li {
                position: relative;
                display: inline-block;
                margin-left: 1em;

                a {
                    color: #ffffff;
                    font-weight: bold;
                    text-decoration: none;
                    border: 0;
                    display: block;
                    padding: 1.5em 0.5em 1.35em 0.5em;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:hover {
                    a {
                        color: #f8a790;
                    }
                }

                &.current {
                    font-weight: 600;

                    &:before {
                        @include vendor('transform', 'rotateZ(45deg)');
                        width: 0.75em;
                        height: 0.75em;
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -0.5em;
                        left: 50%;
                        margin-left: -0.375em;
                        background-color: _palette(accent, bg);
                        background-image: url("images/bg01.png");
                    }

                    a {
                        color: #fff;
                    }
                }

                &.active {
                    a {
                        color: #fff;
                    }

                    &.current {
                        &:before {
                            opacity: 0;
                        }
                    }
                }

                > ul {
                    display: none;
                }
            }
        }
    }

/* Dropotron */

    .dropotron {
        @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0.3), rgba(0,0,0,0))', 'url("images/bg01.png")'));
        background-color: #333;
        border-radius: _size(radius);
        color: #fff;
        min-width: 10em;
        padding: 1em 0;
        text-align: center;
        box-shadow: 0 1em 1em 0 rgba(0,0,0,0.5);
        list-style: none;

        > li {
            line-height: 2em;
            padding: 0 1.1em 0 1em;

            > a {
                color: #c0c0c0;
                text-decoration: none;
                border: 0;
            }

            &.active,
            &:hover {
                > a {
                    color: #fff;
                }
            }
        }

        &.level-0 {
            border-radius: 0 0 _size(radius) _size(radius);
            font-size: 0.9em;
            padding-top: 0;
            margin-top: -1px;
        }
    }

/* Banner */

    #banner-en {
        background-image: url(
            images/tms2.jpeg
        );
        background-position: top center;
        background-size: contain;
        background-color: #fff;
        background-repeat: no-repeat;
        height: 20em;
        text-align: center;
        position: relative;

        header {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #212121;
            background: rgba(66,112,105,0.75);
            color: #fff;
            padding: 1.5em 0;

            h2 {
                display: inline-block;
                margin: 0;
                font-size: 1.25em;
                vertical-align: middle;

                em {
                    opacity: 0.75;
                }

                a {
                    border-bottom-color: transparentize(#fff, 0.5);

                    &:hover {
                        border-bottom-color: transparent;
                    }
                }
            }

            .button {
                vertical-align: middle;
                margin-left: 1em;
            }
        }
    }

    #banner-fr {
        background-image: url(
            images/TMS_Banner_FR.jpg
        );
        background-position: top center;
        background-size: contain;
        background-color: #fff;
        background-repeat: no-repeat;
        height: 20em;
        text-align: center;
        position: relative;

        header {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #212121;
            background: rgba(66,112,105,0.75);
            color: #fff;
            padding: 1.5em 0;

            h2 {
                display: inline-block;
                margin: 0;
                font-size: 1.25em;
                vertical-align: middle;

                em {
                    opacity: 0.75;
                }

                a {
                    border-bottom-color: transparentize(#fff, 0.5);

                    &:hover {
                        border-bottom-color: transparent;
                    }
                }
            }

            .button {
                vertical-align: middle;
                margin-left: 1em;
            }
        }
    }

/* Wrapper */

    .wrapper {
        padding: 5em 0 3em 0;

        &.style1 {
            background: #fff;
        }

        &.style2 {
            p {
                color: #474747;
            }
            color:                  #000000;
            background-color:       #e2eadd;
            background-image:       url(''),         url(''),         url('images/xv.png');
            background-position:    top left,                       bottom left,                    center;
            background-size:        100% 6em,                       100% 6em,                       auto;
            background-repeat:      no-repeat,                      no-repeat,                      repeat;
        }

        &.style3 {
            background-color: _palette(accent, bg);
            @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.15))', 'url("images/bg01.png")'));
            color: _palette(accent, fg);

            .button {
                background: _palette(accent, fg);
                color: _palette(fg);

                &:hover {
                    color: _palette(accent, bg) !important;
                }
            }
        }
        
        &.style4 {
            background-color: _palette(accent, alt);
            @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.15))', 'url("images/bg01.png")'));
            color: _palette(accent, fg);


        }
        
        &.style5 {
            background: #F3F5F5;
        }
    }

/* CTA */

    #cta {
        text-align: center;
        padding: 3.5em 0;

        header {
            h2 {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
            }

            .button {
                vertical-align: middle;
                margin-left: 1em;
            }
        }
    }

/* Footer */

    #footer {
        padding: 4em 0 8em 0;

        a {
            color: _palette(alt);
            border-bottom-color: transparentize(_palette(fg), 0.75);

            &:hover {
                color: _palette(accent, bg);
                border-bottom-color: transparent;
            }
        }

        .container {
            margin-bottom: 4em;
        }

        .icons {
            text-align: center;
            margin: 0;

            a {
                color: _palette(alt);

                &:hover {
                    color: _palette(accent, bg);
                }
            }
        }

        .copyright {
            color: _palette(fg);
            margin-top: 1.5em;
            text-align: center;
            font-size: 0.9em;
        }
    }

/* Wide */

    @include breakpoint('<=wide') {

        /* Basic */

            body, input, select, textarea {
                font-size: 14pt;
                line-height: 1.5em;
            }

        /* Banner */

            #banner {
                height: 20em;
            }

    }

/* Normal */

    @include breakpoint('<=normal') {

        /* Basic */

            body, input, select, textarea {
                font-size: 13pt;
                line-height: 1.5em;
            }

        /* Lists */

            ol {
                padding-left: 1.25em;

                li {
                    padding-left: 0.25em;
                }
            }

        /* Icons */

            ul.icons {
                li {
                    a, span {
                        font-size: 1.5em;
                    }
                }
            }

        /* Header */

            #header {
                padding: 0 0 0 0;

                h1 {
                    padding: 0 0 1.75em 0;
                }
            }

        /* Banner */

            #banner {
                height: 20em;
            }

        /* Wrapper */

            .wrapper {
                padding: 3em 0 1em 0;
            }

        /* CTA */

            #cta {
                padding: 2em 0;
            }

        /* Footer */

            #footer {
                padding: 3em 0 3em 0;

                .container {
                    margin-bottom: 1em;
                }
            }

    }

/* Narrow */

    @include breakpoint('<=narrow') {

        /* Basic */

            body, input, select, textarea {
                font-size: 12pt;
                line-height: 1.5em;
            }

    }

/* Narrower */

    #navPanel, #titleBar {
        display: none;
    }

    @include breakpoint('<=narrower') {

        /* Basic */

            html, body {
                overflow-x: hidden;
            }

            body, input, select, textarea {
                font-size: 13pt;
            }

            h1, h2, h3, h4, h5, h6 {
                margin-bottom: 0.5em;
            }

            header {
                p {
                    margin-top: -0.75em;
                }

                &.major {
                    text-align: center;
                    margin: 0 0 2em 0;

                    h2 {
                        font-size: 1.75em;
                    }

                    p {
                        top: -0.25em;
                        font-size: 1.25em;
                    }
                }
            }

        /* Box */

            .box {
                &.highlight {
                    text-align: left;
                    position: relative;
                    padding-left: 7em;

                    i {
                        position: absolute;
                        margin: 0;
                        left: 0;
                        top: 0.25em;
                    }
                }

                &.post {
                    .inner {
                        margin-left: calc(20% + 2em);
                    }

                    .image {
                        width: 20%;
                    }
                }
            }

        /* Header */

            #header {
                display: none;
            }

        /* Banner */

            #banner {
                height: 18em;

                header {
                    h2 {
                        display: block;
                    }

                    .button {
                        margin: 1em 0 0 0;
                    }
                }
            }

        /* CTA */

            #cta {
                padding: 1.5em 0;

                header {
                    h2 {
                        display: block;
                    }

                    .button {
                        margin: 1em 0 0 0;
                    }
                }
            }

        /* Footer */

            #footer {
                text-align: center;

                .container {
                    margin-bottom: 4em;
                }

                form .actions {
                    @include vendor('justify-content', 'center');
                    width: 100%;
                    margin-left: 0;

                    li {
                        &:first-child {
                            padding-left: 0;
                        }
                    }
                }
            }

        /* Nav */

            #page-wrapper {
                @include vendor('backface-visibility', 'hidden');
                @include vendor('transition', 'transform #{_duration(navPanel)} ease');
                padding-bottom: 1px;
                padding-top: 44px;
            }

            #titleBar {
                @include vendor('backface-visibility', 'hidden');
                @include vendor('transition', 'transform #{_duration(navPanel)} ease');
                display: block;
                height: 44px;
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: _misc(z-index-base) + 1;
                background-color: #427069;
                @include vendor('background-image', ('linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.3))', 'url("images/bg01.png")'));
                height: 44px;
                line-height: 44px;
                box-shadow: 0 4px 0 0 _palette(accent, bg);

                .title {
                    display: block;
                    position: relative;
                    font-weight: 600;
                    text-align: center;
                    color: #fff;
                    z-index: 1;

                    em {
                        font-style: normal;
                        font-weight: 300;
                    }
                }

                .toggle {
                    @include icon(false, solid);
                    border: 0;
                    height: 60px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 80px;
                    z-index: 2;

                    &:before {
                        content: '\f0c9';
                        display: block;
                        height: 44px;
                        line-height: inherit;
                        text-align: center;
                        width: 44px;
                        color: #fff;
                        opacity: 0.5;
                    }

                    &:active {
                        &:before {
                            opacity: 0.75;
                        }
                    }
                }
            }

            #navPanel {
                background-color: #427069;
                box-shadow: inset -1px 0 3px 0 rgba(0,0,0,0.5);
                @include vendor('background-image', ('linear-gradient(left, rgba(0,0,0,0) 75%, rgba(0,0,0,0.15))', 'url("images/bg01.png")'));
                @include vendor('backface-visibility', 'hidden');
                @include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
                @include vendor('transition', ('transform #{_duration(navPanel)} ease'));
                display: block;
                height: 100%;
                left: 0;
                overflow-y: auto;
                position: fixed;
                top: 0;
                width: _size(navPanel);
                z-index: _misc(z-index-base) + 2;

                .link {
                    border-bottom: 0;
                    border-top: solid 1px rgba(255,255,255,0.1);
                    color: #888;
                    display: block;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 1em 0 1em;
                    text-decoration: none;
                    font-weight: bold;

                    &:first-child {
                        border-top: 0;
                    }

                    &.depth-0 {
                        color: #fff;
                    }

                    .indent-1 { display: inline-block; width: 1em; }
                    .indent-2 { display: inline-block; width: 2em; }
                    .indent-3 { display: inline-block; width: 3em; }
                    .indent-4 { display: inline-block; width: 4em; }
                    .indent-5 { display: inline-block; width: 5em; }
                }
            }

            body {
                &.navPanel-visible {
                    #page-wrapper {
                        @include vendor('transform', 'translateX(#{_size(navPanel)})');
                    }

                    #titleBar {
                        @include vendor('transform', 'translateX(#{_size(navPanel)})');
                    }

                    #navPanel {
                        @include vendor('transform', 'translateX(0)');
                    }
                }
            }

    }

/* Mobile */

    @include breakpoint('<=mobile') {

        /* Basic */

            body, input, select, textarea {
                font-size: 11pt;
                line-height: 1.35em;
            }

            h2 {
                font-size: 1.25em;
                letter-spacing: 0;
                line-height: 1.35em;
            }

            h3 {
                font-size: 1em;
                letter-spacing: 0;
                line-height: 1.35em;
            }

            header {
                p {
                    margin-top: -0.5em;
                    font-size: 1em;
                }

                &.major {
                    padding: 0 20px;

                    h2 {
                        font-size: 1.25em;
                    }

                    p {
                        top: 0;
                        margin-top: 1.25em;
                        font-size: 1em;
                    }
                }
            }

        /* Menu */

            ul.menu {
                li {
                    border: 0;
                    padding: 0;
                    margin: 0;
                    display: block;
                    line-height: 2em;
                }
            }

        /* Banner */

            #banner {
                height: 14em;
            }

        /* Wrapper */

            .wrapper {
                padding: 2em 0 1px 0;
            }

    }

/* Mobile (Portrait) */

    @include breakpoint('<=mobilep') {

        /* Icon */

            .icon {
                &.major {
                    width: 4em;
                    height: 4em;
                    line-height: 4em;
/*                    box-shadow: 0 0 0 7px white, 0 0 0 8px _palette(border);*/

                    &:before {
                        font-size: 24px;
                    }
                }
            }

        /* Button */

            input[type="submit"],
            input[type="reset"],
            input[type="button"],
            button,
            .button {
                width: 100%;
                display: block;
            }

        /* Box */

            .box {
                &.highlight {
                    padding-left: calc(4em + 30px);
                }

                &.post {
                    .inner {
                        margin-left: calc(30% + 20px);
                    }

                    .image {
                        width: 30%;
                    }
                }
            }

        /* Banner */

            #banner {
                height: 14em;

                header {
                    padding: 20px;
                }
            }

        /* Wrapper */

            .wrapper {
                padding: 2em 20px 1px 20px;
            }

        /* CTA */

            #cta {
                padding: 20px;
            }

        /* Footer */

            #footer {
                padding: 2em 20px;
                text-align: left;
            }

    }

@import 'custom.scss';
